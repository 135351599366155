import './reset.scss'
import * as s from './stub.module.scss'

const ROOT = document.getElementById('root')

if (ROOT?.innerHTML === '') {
  ROOT.innerHTML = `
<div class="${s.stub}">
  <div class="${s.main}">
    <img alt="logo" class="${s.logo}" src="${window.PLATFORM.logoFull_M}" />
    <div class="${s.loader}"></div>
  </div>
</div>
`
}
